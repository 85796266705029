<template>
    <list-page
        page-title="Bill Imports"
        page-icon="mdi-account-multiple-outline"
        collection-name="billImport"
        :items-per-page="20"
        :reloadTrigger="reloadTrigger"
        @data="loadedData"
        is-filter
    >
        <template v-slot:toolbar>
            <div class="d-flex">
                <!--            <btn label="Import Email" color="green" size="small" @click="importEmail" />-->
                <btn label="Start Email" icon-code="mdi-mail" color="green" size="small" @click="emailPollerAction('start')" />
                <btn label="Stop Email" icon-code="mdi-cancel" color="red" size="small" @click="emailPollerAction('stop')" />
                <div v-if="isEmailPollerRunning !== null" class="mx-4">
                    {{ isEmailPollerRunning ? 'Running' : 'Stopped' }} - {{ emailCountText }}
                </div>
            </div>
        </template>
    </list-page>
</template>

<script>
import ListPage from '@/components/pages/List';

import api from '@/api';

export default {
    name: 'BillImportList',
    components: { ListPage },
    data: () => ({
        reloadTrigger: 0,
        isEmailPollerRunning: null,
        emailCountValue: null,
        emailCountText: null,
        pollingInterval: null,
        requestDate: null,
        listData: null,
    }),
    methods: {
        async importEmail() {
            console.log('importEmail.');
            this.$store.dispatch('setLoading', 'Importing Email');

            const result = await api.post(this.$store, 'billimport/email', {});
            if (result.data) {
                const { isEmailFound, isAttachmentSaved } = result.data;
                if (!isEmailFound) {
                    this.$store.dispatch('setInfo', 'Mailbox empty');
                } else if (!isAttachmentSaved) {
                    this.$store.dispatch('setInfo', 'Email had no PDF attachment');
                }
            }
            this.$store.dispatch('setLoading');
            this.reloadTrigger++;
        },
        async emailPollerAction(actionType) {
            const requestDate = new Date();
            this.requestDate = requestDate;
            const result = await api.post(this.$store, `email/poll/${actionType}`, {});
            if (this.requestDate === requestDate && result.data) {
                const { isEmailPollerRunning, emailCountText, emailCountValue } = result.data;

                this.isEmailPollerRunning = isEmailPollerRunning;
                this.emailCountText = emailCountText;
                this.emailCountValue = emailCountValue;

                console.log('emailPollerAction returned', actionType, result.data);
            }
        },
        loadedData(listData) {
            this.listData = listData;
        },
        async checkForNewData() {
            if (!this.listData || !this.listData[0]) return;

            const vendorId = this.listData[0].vendorLink ? this.listData[0].vendorLink.id : '';
            const result = await api.get(this.$store, `billimport?latestId=${this.listData[0].id}&vendorId=${vendorId}`);
            if (result.data) {
                console.log('new records result', result.data);
                if (result.data.isNewRecords) {
                    this.reloadTrigger++;
                }
            }
        },
    },
    async mounted() {
        this.emailPollerAction('count');
        this.pollingInterval = setInterval(() => {
            this.emailPollerAction('count');
            this.checkForNewData();

        }, 10 * 1000);
    },
    destroyed() {
        if (this.pollingInterval) clearInterval(this.pollingInterval);
    },
};
</script>

<style scoped></style>
